import React, { useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import { navigate } from "gatsby";
import { isDisabled } from "../utils/isDisabled";
import Header from "../components/Header";
import { Helmet } from "react-helmet";
import "./Day.css";
import dayjs from "dayjs";
require('dayjs/locale/hu');
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
dayjs.locale("hu");

const Day = ({ data }) => {
  const { word, task, nextPage, previousPage, name } =
    data.allSitePage.nodes[0].pageContext;

  const [isLoadable, setIsLoadable] = useState(false);

  useEffect(() => {
    if (!isDisabled(name)) {
      navigate("/");
      return null;
    } else {
      setIsLoadable(true);
    }
  }, []);

  const shouldBeVisible = (input) => {
    if (input === null) {
      return false;
    } else {
      return isDisabled(input);
    }
  };

  const getFullDate = (monthDay) => {
    const year = dayjs().year();
    const fullLongDate = dayjs(`${year}-${monthDay}`).format('YYYY. MMMM DD.');
    return fullLongDate;
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Adventi Kalendárium - {getFullDate(name)}</title>
      </Helmet>
      {isLoadable ? (
        <>
          <Header></Header>
          <div className="day-wrapper">
            <div className="card">
              <div className="card-header">
                <h3>{getFullDate(name)}</h3>
              </div>
              <div className="word-wrapper">
                <p className="helper">Ige:</p>
                <p dangerouslySetInnerHTML={{ __html: word }}></p>
              </div>
              <div className="task-wrapper">
                <p className="helper">Feladat:</p>
                <p>{task}</p>
              </div>
              <div className="card-actions">
                {shouldBeVisible(previousPage) ? (
                  <Link to={`/${previousPage}`}>
                    <button className="page-button">Előző</button>
                  </Link>
                ) : (
                  <div></div>
                )}
                {shouldBeVisible(nextPage) ? (
                  <Link to={`/${nextPage}`}>
                    <button className="page-button">Következő</button>
                  </Link>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export const query = graphql`
  query ($path: String!) {
    allSitePage(filter: { path: { eq: $path } }) {
      nodes {
        pageContext
      }
    }
  }
`;

export default Day;
